<template>
  <div class="header">
    <div class="title">合院快跑后台管理系统</div>
    <div class="right">
      <div class="user">{{ username }}</div>
      <el-button type="info" @click="logout">退出</el-button>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";

const username = ref("用户");
import { useRouter } from "vue-router";
const router = useRouter();
onMounted(()=>{
  username.value=localStorage.getItem("name")
})
const logout = () => {
  router.push({
    path: "/login",
  });
};
</script>

<style lang="scss" scoped>
.header {
  background-color: #292961;
  line-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  .title{
    padding-left: 20px;
    font-weight: bold;
  }
  .right {
    display: flex;
    align-items: center;
    .user {
      margin-right: 20px;
    }
  }
}
</style>
