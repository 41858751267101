<template>
  <div class="login">
    <div class="content">
      <div class="login-title">小区小蜜蜂后台管理系统</div>
      <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        :rules="rules"
        label-width="auto"
        class="demo-ruleForm"
      >
        <el-form-item label="用户名：" prop="username" style="width: 300px">
          <el-input  :prefix-icon="User" v-model="ruleForm.username" autocomplete="off" />
        </el-form-item>

        <el-form-item label="密码：" prop="password" style="width: 300px">
          <el-input
            v-model="ruleForm.password"
            type="password"
            :prefix-icon="Lock"
            autocomplete="off"
          />
        </el-form-item>

        <div class="bottom-line">
          <el-button type="primary" @click="submitForm(ruleFormRef)">
            登录
          </el-button>
          <el-button @click="resetForm(ruleFormRef)">重置</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref } from "vue";
import { login } from "./api/index";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
import { User, Lock } from '@element-plus/icons-vue'
const ruleFormRef = ref();
const router = useRouter();

const validateUserName = (rule, value, callback) => {
  if (value === "") {
    callback(new Error("用户名不能为空"));
  } else {
    callback();
  }
};
const validatePassword = (rule, value, callback) => {
  if (value === "") {
    callback(new Error("密码不能为空"));
  } else {
    callback();
  }
};

const ruleForm = ref({
  username: "",
  password: "",
});

const rules = ref({
  username: [{ validator: validateUserName, trigger: "blur" }],
  password: [{ validator: validatePassword, trigger: "blur" }],
});
const submitForm = (formEl) => {
  if (!formEl) return;
  formEl.validate((valid) => {
    if (valid) {
      console.log("submit!");
      login(ruleForm.value)
        .then((res) => {
          ElMessage({
            message: "登录成功",
            type: "success",
          });
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("name", res.data?.name||"用户");
          router.push({
            path: "/community",
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log("error submit!");
    }
  });
};

const resetForm = (formEl) => {
  if (!formEl) return;
  formEl.resetFields();
};
</script>
<style lang="scss" scoped>
.login {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-image: url("./assets/img/login-background.jpg");
  background-repeat: no-repeat;
  background-position: center center; /* 背景图片居中显示 */
  background-size: cover; /* 让背景图片填满盒子 */
  .content {
    width: 400px;
    border-radius: 10px;
    background-color: #fff;
    padding: 10px;
    .login-title {
      text-align: center;
      font-weight: bold;
      font-size: 20px;
    }
    .demo-ruleForm {
      padding: 20px;
      .bottom-line {
        display: flex;
        justify-content: center;
      }
    }
  }
}
</style>
