import request from "@/utils/request";

// 上传oss
// export function uploadFile(file) {
//   let fd = new FormData();
//   fd.append("file", file);
//   fd.append("subName", "test");
//   // 如果上传的是一个fileList，那自己加个判断再遍历 append一下就行
//   return request({
//     url: "/oss/putObject",
//     method: "post",
//     data: fd,
//   });
// }
// 登录
export const login = (data) => {
  return request({
    url: "/login",
    method: "post",
    data: data,
  });
};

// 获取站点列表接口
export const getStaionList = (data) => {
  return request({
    url: `/admin/getStationByName`,
    method: "get",
    params: data,
  });
};
// 通过id获取站点详情
export const getStationDetail = (data) => {
  return request({
    url: `/admin/getStationDetail`,
    method: "get",
    params: data,
  });
};
// 通过id修改站点内容
export const updateStation = (data) => {
  return request({
    url: `/admin/updateStation`,
    method: "put",
    data: data,
  });
};
// 修改站点状态
export const updataStationStatus = (data) => {
  return request({
    url: `/admin/updataStationStatus`,
    method: "get",
    params: data,
  });
};
// 通过小区id获取站点列表接口
export const getCommunityById = (data) => {
  return request({
    url: `/admin/getCommunityById`,
    method: "get",
    params:data
  });
};
// 通过id删除站点
export const delStationById = (id) => {
  return request({
    url: `/admin/delStationById/${id}`,
    method: "delete",
  });
};
// 新增站点
export const addStation = (data) => {
  return request({
    url: `/admin/addStation`,
    method: "post",
    data: data,
  });
};
// 获取小区列表接口
export const getCommunityList = (data) => {
  return request({
    url: "/admin/getCommunityList",
    method: "get",
    params: data,
  });
};
// 搜索小区列表接口
export const getCommunityByName = (data) => {
  return request({
    url: "/admin/getCommunityByName",
    method: "get",
    params: data,
  });
};
// 通过ids获取小区列表接口
export const getCommunityListByIds = (data) => {
  return request({
    url: "/admin/getCommunityListByIds",
    method: "post",
    data: data,
  });
};
// 新增小区接口
export const addCommunity = (data) => {
  return request({
    url: "/admin/addCommunity",
    method: "post",
    data: data,
  });
};
// 编辑小区接口
export const updateCommunity = (data) => {
  return request({
    url: "/admin/updateCommunity",
    method: "put",
    data: data,
  });
};

// 删除小区列表接口
export const delCommunityById = (id) => {
  return request({
    url: `/admin/delCommunityById/${id}`,
    method: "delete",
  });
};

// 新增用户接口
export const addUser = (data) => {
  return request({
    url: "/creteUser",
    method: "post",
    data: data,
  });
};
// 获取用户列表接口
export const getUserListPage = (data) => {
  return request({
    url: "/admin/getUserListPage",
    method: "get",
    params: data,
  });
};
// 根据id获取用户详情接口
export const getDetailById = (data) => {
  return request({
    url: "/admin/employee/getDetailById",
    method: "get",
    params: data,
  });
};
// 删除用户
export const deleteUserByID = (id) => {
  return request({
    url: `/admin/deleteUserById/${id}`,
    method: "delete",
  });
};
// 修改用户
export const updateEmplyee = (data) => {
  return request({
    url: `/admin/updateUserByID`,
    method: "put",
    data: data,
  });
};
