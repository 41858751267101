import { createRouter, createWebHashHistory } from "vue-router";
import layoutIndex from "../layout/layoutIndex.vue";
import Login from "../login.vue";

const routes = [
  {
    path: "",
    redirect: "/community",
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/home",
    name: "home",
    component: layoutIndex,
    children: [
      {
        path: "/community",
        name: "community",
        component: () => import("../views/community/index.vue"),
      },
      {
        path: "/station",
        name: "station",
        component: () => import("../views/station/index.vue"),
      },
      {
        path: "/user",
        name: "user",
        component: () => import("../views/user/index.vue"),
      },
      {
        path: "/testMap",
        name: "testMap",
        component: () => import("../views/testMap/index.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
