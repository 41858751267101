<template>
  <router-view />
  <div class="beian">
    <a href="https://beian.mps.gov.cn/#/query/webSearch">
      蜀ICP备2024080758号
    </a>
    &emsp;
    <a
      href="https://beian.miit.gov.cn/?spm=5176.beian-pc.J_9220772140.60.f0d079feim80Mb#/Integrated/index"
    >
      蜀ICP备2024080758号-1
    </a>
  </div>
</template>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
}
.beian {
  display: flex;
  justify-content: center;
  font-size: 12px;
  position: fixed;
  bottom: 20px;
  width: 100vw;
  a {
    color: #9e9696 !important;
  }
}
</style>
